import * as React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { HeroLanding } from 'components/content/hero-landing/HeroLanding';
import { Container } from 'components/container/Container';
import { Button } from 'components/buttons/Button';
import { RegisterFormNow } from 'components/components-page/register-now-form/RegisterNowForm';
import { VideoModal } from 'components/components-page/video-modal/VideoModal';

import { LandingLayout } from 'layouts'
/*
import { TweenLite } from 'gsap';
*/
import s from 'styles/pages/register-now.scss';

const menuItems = [
	{
		button: false,
		name: 'Features',
		to: 'features',
		anchor: true,
	},
	{
		button: true,
		name: 'Register now',
		to: 'register-now',
		anchor: true,
	},
]

export default ({ data }) => {
	let refForm = null
	console.log(data)
	const scrollTo = elem => {
		const thisY = elem.offsetTop -90
		window.scrollTo({
		    top: thisY,
		    behavior: 'smooth'
		})
	}
	return(
	  <LandingLayout menuItems={menuItems}>
	    <Helmet>
            <title>Hair, Beauty, Fittnes and Aesthetics</title>
            <meta name="description" content="Take control of your hair and beauty business. Growing your beauty business can be made easier with JustMe." />
		    <script>
		        {
				  `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				  n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
				  n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
				  t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
				  document,'script','https://connect.facebook.net/en_US/fbevents.js');
				  fbq('init', ${process.env.FACEBOOK_PIXEL_ID}); 
				  fbq('track', 'PageView');`
		        }
		    </script>
		    <noscript>
		    {
		    	`<img height="1" width="1" style="display:none"
				  src="https://www.facebook.com/tr?id=${process.env.FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1"
				/>`
			}
		    </noscript>

	    </Helmet>
	    <HeroLanding imgSrc={data.hero}>
	    	<Container>
		    	<div className={s.landinghero__wrap}>
		    		<div className={s.landinghero__row}>
		    			{
		    				/*
		    			<h1 className={s.landinghero__h1}>Hello you!</h1>

		    			<h2 className={s.landinghero__h2}>Take control of your hair, beauty and fitness business by registering your services today. It’s completely FREE.</h2>
		    			<h2 className={s.landinghero__h2}>The JustMe appointment booking app is now available for clients to download. Add your business today:</h2>
		    			<div>
			    			<div className={s.buttons__inline}>
			    				<Button onClick={ () => { scrollTo(refForm) } }>Register now</Button>
			    			</div>
			    			<div className={s.buttons__inline}>
							    <VideoModal idYoutube="I_3e0bfD1nA">
							    	<Button className={s.button__video}>Explainer Video</Button>
							    </VideoModal>
						    </div>
					    </div>
		    				*/
		    			}
		    			<h1 className={s.landinghero__h1}>We are currently evaluating the Justme offering.</h1>


					    {
					    	/*

			    		<div className={s.landinglogos}>
			    			<div className={s.landinglogos__appcontainer}>
			    			{ data.applebadge &&
			    				<a className={s.landinglogos__appcontainer__link} rel="noopener" target="_blank" href="https://apps.apple.com/us/app/justme-for-beauty/id1484201330?ls=1">
							    <Img
							    	className={s.landinglogos__appbadge}
							      	fluid={data.applebadge.childImageSharp.fluid}
							    />
							    </a>
			    			}
			    			</div>
			    			<div className={s.landinglogos__appcontainer}>
			    			{ data.googlebadge &&
			    				<a className={s.landinglogos__appcontainer__link} rel="noopener" target="_blank" href="https://play.google.com/store/apps/details?id=agency.vital.justmeapp">
							    <Img
							    	className={s.landinglogos__appbadge__google}
							      	fluid={data.googlebadge.childImageSharp.fluid}
							    />
							    </a>
			    			}
			    			</div>


			    		</div>
			    			*/
			    		}
		    		</div>
		    	</div>
	    	</Container>
	    </HeroLanding>

	    {
	    	/*
	    <div className={`${s.sectionlanding} ${s.sectionlanding__showcase} ${s.sectionlanding__light}`} id="how">
	    	<Container>
	    		<div className={s.sectionlanding__row}>
	    			<div className={s.sectionlanding__col}>
	    				<h4 className={s.title__subtitle}>Just for you</h4>
			    		<h3 className={s.title__big}>Get started with JustMe</h3>
			    		<p className={s.text}>
							To get started, we need to know a little bit about you. Please complete the register details below and we’ll respond to you quickly.
						</p>
						<p className={s.text}>
							We accept mobile and salon businesses.
						</p>
						<p className={s.text}>
							Start getting new clients in 2020!
			    		</p>

		    			<div className={`${s.buttons__inline} ${s.sectionlanding__showcase__cta}`}>
		    				<Button onClick={ () => { scrollTo(refForm) } }>Register now</Button>
		    			</div>

	    			</div>
	    			<div className={s.sectionlanding__col}>
	    				{
	    					data.thumbnailvideo &&
	    					<VideoModal idYoutube="I_3e0bfD1nA">
		    					<div className={s.videothumb}>
								    <Img
								    	className={s.videothumb__img}
								      	fluid={data.thumbnailvideo.childImageSharp.fluid}
								    />
		    						<div className={s.videothumb__icon}>
		    							<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><g strokeWidth="1"><polygon fill="none" stroke="#866969" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" points="2.5,0.5 14.5,8 2.5,15.5 "></polygon></g></svg>
		    						</div>
		    					</div>
	    					</VideoModal>
	    				}
	    			</div>
	    		</div>
		    </Container>
	    </div>


	    <div className={`${s.sectionlanding} ${s.sectionlanding__features}`} id="features">
	    	<Container>

	    		<div className={s.textcenter}>
		    		<h3 className={s.title__subtitle}>Growing your business can be made easier with JustMe</h3>
	    		</div>

	    		<ul className={s.sectionlanding__features__list}>
	    			<li>
	    				Take bookings 24/7
	    			</li>
	    			<li>
	    				Control your own availability
	    			</li>
	    			<li>
	    				Get client reviews
	    			</li>
	    			<li>
	    				Attract repeat customers
	    			</li>
	    			<li>
	    				Increase your customer base
	    			</li>
	    			<li>
	    				Manage your prices
	    			</li>
	    		</ul>
		    </Container>
	    </div>
	    <div className={`${s.sectionlanding} ${s.sectionlanding__showcase} ${s.sectionlanding__inverse}`} id="incontrol">
	    	<Container>
	    		<div className={s.sectionlanding__row}>
  			
	    			<div className={s.sectionlanding__col}>
			    		<h3 className={s.title__big}>In control of your own business</h3>
			    		<p className={s.text}>
							We’ve spent a lot of time talking to salons and mobile hair and beauty providers and we know what they want – Simplicity, speed and to be in control of their own business.With the JustMe app for clients to easily book and manage their calendars via the website, running your business becomes easier.
						</p>
						<p className={s.text}>
							We’ll be committing ourselves to drive visitors to the website and app to further help you get new clients.
						</p>

		    			<div className={`${s.buttons__inline} ${s.sectionlanding__showcase__cta}`}>
		    				<Button onClick={ () => { scrollTo(refForm) } }>Register now</Button>
		    			</div>

	    			</div>
	    			<div className={s.sectionlanding__col}>
	    				{
	    					data.iphoneservices &&
						    <Img
						    	className={s.sectionlanding__showcase__iphone}
						      	fluid={data.iphoneservices.childImageSharp.fluid}
						    />
	    				}
	    			</div>	  
	    		</div>
		    </Container>
	    </div>
	    <div className={`${s.sectionlanding} ${s.sectionlanding__showcase}`} id="availability">
	    	<Container>
	    		<div className={s.sectionlanding__row}>
	    			<div className={s.sectionlanding__col}>
	    				<h4 className={s.title__subtitle}>Your own calendar</h4>
			    		<h3 className={s.title__big}>Availability management 24/7</h3>
			    		<p className={s.text}>
							When you need to block out time in your day or show more availability, you are in control of the easy to use calendar feature via the website. Reduce phone calls or trawling through emails – free up your time!
						</p>

		    			<div className={`${s.buttons__inline} ${s.sectionlanding__showcase__cta}`}>
		    				<Button onClick={ () => { scrollTo(refForm) } }>Register now</Button>
		    			</div>

	    			</div>
	    			<div className={s.sectionlanding__col}>
	    				{
	    					data.calendar &&
						    <Img
						    	className={s.sectionlanding__showcase__iphone}
						      	fluid={data.calendar.childImageSharp.fluid}
						    />
	    				}
	    			</div>
	    		</div>
		    </Container>
	    </div>



	    <div className={`${s.sectionlanding} ${s.sectionlanding__showcase} ${s.sectionlanding__inverse}`} id="anyday">
	    	<Container>
	    		<div className={s.sectionlanding__row}>
    			
	    			<div className={s.sectionlanding__col}>
			    		<h3 className={s.title__big}>Take bookings, any day, any time</h3>
			    		<p className={s.text}>
							When you wake up to new client bookings and all you had to do was sleep, you know your business is moving forward!
						</p>
						<p className={s.text}>
							Your clients want the choice of when  they book, not just between 9 and 5, so, let’s give them that choice.
						</p>

		    			<div className={`${s.buttons__inline} ${s.sectionlanding__showcase__cta}`}>
		    				<Button onClick={ () => { scrollTo(refForm) } }>Get Started</Button>
		    			</div>

	    			</div>
	    			<div className={s.sectionlanding__col}>
	    				{
	    					data.iphonesearch &&
						    <Img
						    	className={s.sectionlanding__showcase__iphone}
						      	fluid={data.iphonesearch.childImageSharp.fluid}
						    />
	    				}
	    			</div>	
	    		</div>
		    </Container>
	    </div>
	    <div className={`${s.sectionlanding}`} id="register-now" ref={ ref => { refForm = ref } }>
	    	<Container>
	    		<div className={s.textcenter}>
		    		<h3 className={s.title__extra}>Join the JustMe revolution</h3>
		    		<p className={s.text}>
						Please complete the details below to register now.<br />
		    		</p>		    		
	    		</div>
	    		<RegisterFormNow />
	    	</Container>
	    </div>
			*/
		}
	    <div className={s.footer}>
	    	<Container>
		    	<div className={s.footer__flex}>
		    		<div className={s.footer__col}>
		    			<ul className={s.footer__social}>
		    				<li>
		    					<a target="_blank" rel="noopener" href="https://www.facebook.com/justmeforbeauty">
									<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
										<title>Facebook</title>
										<path d="M19.32924,46V25.92955h-6.75413v-7.82187h6.75413v-5.76839C19.32924,5.64511,23.41782,2,29.38949,2 c2.86049,0,5.31894,0.21297,6.03539,0.30816v6.99581l-4.14167,0.00188c-3.24773,0-3.87657,1.54327-3.87657,3.80792v4.99391h7.74553 l-1.0085,7.82187h-6.73704V46H19.32924z"/>
									</svg>
		    					</a>
		    				</li>
		    				{
		    					/*
			    				<li>
			    					<a target="_blank" rel="noopener" href="">
										<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
											<title>Twitter</title>
											<path d="M48,9.1c-1.8,0.8-3.7,1.3-5.7,1.6c2-1.2,3.6-3.1,4.3-5.4c-1.9,1.1-4,1.9-6.3,2.4c-1.8-1.9-4.4-3.1-7.2-3.1 c-5.4,0-9.8,4.4-9.8,9.8c0,0.8,0.1,1.5,0.3,2.2C15.5,16.2,8.2,12.3,3.3,6.3C2.5,7.8,2,9.4,2,11.2c0,3.4,1.7,6.4,4.4,8.2 c-1.6-0.1-3.1-0.5-4.5-1.2c0,0,0,0.1,0,0.1c0,4.8,3.4,8.8,7.9,9.7c-0.8,0.2-1.7,0.3-2.6,0.3c-0.6,0-1.3-0.1-1.9-0.2 c1.3,3.9,4.9,6.8,9.2,6.8c-3.4,2.6-7.6,4.2-12.2,4.2c-0.8,0-1.6,0-2.3-0.1c4.4,2.8,9.5,4.4,15.1,4.4c18.1,0,28-15,28-28 c0-0.4,0-0.9,0-1.3C45,12.8,46.7,11.1,48,9.1z"></path>									
										</svg>				
			    					</a>
			    				</li>

		    					*/
		    				}
		    				<li>
		    					<a target="_blank" rel="noopener" href="https://www.instagram.com/justme4justyou/">
									<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
										<title>Instagram</title>
										<path d="M24,4.324c6.408,0,7.167,0.024,9.698,0.14c2.731,0.125,5.266,0.672,7.216,2.622 c1.95,1.95,2.497,4.485,2.622,7.216c0.115,2.531,0.14,3.29,0.14,9.698s-0.024,7.167-0.14,9.698 c-0.125,2.731-0.672,5.266-2.622,7.216c-1.95,1.95-4.485,2.497-7.216,2.622c-2.53,0.115-3.289,0.14-9.698,0.14 s-7.168-0.024-9.698-0.14c-2.731-0.125-5.266-0.672-7.216-2.622c-1.95-1.95-2.497-4.485-2.622-7.216 c-0.115-2.531-0.14-3.29-0.14-9.698s0.024-7.167,0.14-9.698c0.125-2.731,0.672-5.266,2.622-7.216c1.95-1.95,4.485-2.497,7.216-2.622 C16.833,4.349,17.592,4.324,24,4.324 M24,0c-6.518,0-7.335,0.028-9.895,0.144c-3.9,0.178-7.326,1.133-10.077,3.884 c-2.75,2.75-3.706,6.175-3.884,10.077C0.028,16.665,0,17.482,0,24c0,6.518,0.028,7.335,0.144,9.895 c0.178,3.9,1.133,7.326,3.884,10.077c2.75,2.75,6.175,3.706,10.077,3.884C16.665,47.972,17.482,48,24,48s7.335-0.028,9.895-0.144 c3.899-0.178,7.326-1.133,10.077-3.884c2.75-2.75,3.706-6.175,3.884-10.077C47.972,31.335,48,30.518,48,24s-0.028-7.335-0.144-9.895 c-0.178-3.9-1.133-7.326-3.884-10.077c-2.75-2.75-6.175-3.706-10.077-3.884C31.335,0.028,30.518,0,24,0L24,0z"></path> <path d="M24,11.676c-6.807,0-12.324,5.518-12.324,12.324S17.193,36.324,24,36.324 S36.324,30.807,36.324,24S30.807,11.676,24,11.676z M24,32c-4.418,0-8-3.582-8-8s3.582-8,8-8s8,3.582,8,8S28.418,32,24,32z"></path> <circle cx="36.811" cy="11.189" r="2.88"></circle>
									</svg>
		    					</a>
		    				</li>
		    				{
			    				/*
			    				<li>

			    					<a target="_blank" rel="noopener" href="">
										<svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
											<title>LinkedIn</title>
											<path d="M46,0H2C0.9,0,0,0.9,0,2v44c0,1.1,0.9,2,2,2h44c1.1,0,2-0.9,2-2V2C48,0.9,47.1,0,46,0z M14.2,40.9H7.1V18 h7.1V40.9z M10.7,14.9c-2.3,0-4.1-1.8-4.1-4.1c0-2.3,1.8-4.1,4.1-4.1c2.3,0,4.1,1.8,4.1,4.1C14.8,13,13,14.9,10.7,14.9z M40.9,40.9 h-7.1V29.8c0-2.7,0-6.1-3.7-6.1c-3.7,0-4.3,2.9-4.3,5.9v11.3h-7.1V18h6.8v3.1h0.1c0.9-1.8,3.3-3.7,6.7-3.7c7.2,0,8.5,4.7,8.5,10.9 V40.9z"/>
										</svg>
			    					</a>
			    				</li>
			    				*/


		    				}

		    			</ul>
		    		</div>


		    		<div className={s.footer__col}>
		    		{/*
		    			<div className={s.footer__appcontainer}>
		    			{ data.applebadge &&
		    				<a className={s.footer__appcontainer__link} rel="noopener" target="_blank" href="https://apps.apple.com/us/app/justme-for-beauty/id1484201330?ls=1">
						    <Img
						    	className={s.footer__appbadge}
						      	fluid={data.applebadge.childImageSharp.fluid}
						    />
						    </a>
		    			}
		    			</div>
		    			<div className={s.footer__appcontainer}>
		    			{ data.googlebadge &&
		    				<a className={s.footer__appcontainer__link} rel="noopener" target="_blank" href="https://play.google.com/store/apps/details?id=agency.vital.justmeapp">
						    <Img
						    	className={s.footer__appbadge}
						      	fluid={data.googlebadge.childImageSharp.fluid}
						    />
						    </a>
		    			}
		    			</div>

					*/}
		    		</div>
		    	</div>
		    	<div className={`${s.footer__flex} ${s.footer__subfooter}`}>
	    			<div className={s.footer__subfooter__credits}>
	    				All rights reserved &copy;  { new Date().getFullYear() }
	    			</div>
	    			<div className={s.footer__subfooter__copyright}>
	    				Site by <a href="https://vital.agency/" rel="noopener" target="_blank" className={s.footer__subfooter__copyright__link}>Vital Agency</a>
	    			</div>
		    	</div>
	    	</Container>
	    </div>

	  </LandingLayout>
	)
};


export const query = graphql`
  	query  {
		hero: file(relativePath: { eq: "landing/register-now.jpg" }) {
		      childImageSharp {
		        fluid(maxWidth: 1920, quality: 100) {
		          ...GatsbyImageSharpFluid_withWebp
		        }
		      }
		}
		applebadge: file(relativePath: { eq: "app/app-store-badge.png" }) {
		      childImageSharp {
		        fluid(maxWidth: 200, quality: 80) {
		          ...GatsbyImageSharpFluid_withWebp_noBase64
		        }
		      }
		}
		googlebadge: file(relativePath: { eq: "app/google-play-badge.png" }) {
		      childImageSharp {
		        fluid(maxWidth: 200, quality: 80) {
		          ...GatsbyImageSharpFluid_withWebp_noBase64
		        }
		      }
		}
		thumbnailvideo: file(relativePath: { eq: "landing/video-explainer.png" }) {
		      childImageSharp {
		        fluid(maxWidth: 800, quality: 80) {
		          ...GatsbyImageSharpFluid_withWebp
		        }
		      }
		}
		iphoneservices: file(relativePath: { eq: "landing/iphone-home.png" }) {
		      childImageSharp {
		        fluid(maxWidth: 600, quality: 80) {
		          ...GatsbyImageSharpFluid_withWebp
		        }
		      } 
		}
		iphonesalon: file(relativePath: { eq: "landing/iphone-salon.png" }) {
		      childImageSharp {
		        fluid(maxWidth: 600, quality: 80) {
		          ...GatsbyImageSharpFluid_withWebp
		        }
		      }
		}
		iphonesearch: file(relativePath: { eq: "landing/iphone-search.png" }) {
		      childImageSharp {
		        fluid(maxWidth: 600, quality: 80) {
		          ...GatsbyImageSharpFluid_withWebp
		        }
		      }
		}
		calendar: file(relativePath: { eq: "landing/calendar.png" }) {
		      childImageSharp {
		        fluid(maxWidth: 600, quality: 80) {
		          ...GatsbyImageSharpFluid_withWebp
		        }
		      }
		}
  	}
`
